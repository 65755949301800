<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 13:21:46
 * @LastEditTime: 2020-05-06 13:31:48
 -->
<template>
    <div v-loading="loading">
        <el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
            <el-form-item label="时间">
                <el-date-picker
                    v-model="searchForm.startMonth"
                    type="month"
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                     style="width: 175px;" />
                <span style="margin:0 5px;">-</span>
                <el-date-picker
                    v-model="searchForm.endMonth"
                    type="month"
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                     style="width: 175px;" />
            </el-form-item>
            <el-form-item label="部门">
                <el-input v-model="searchForm.orgName" placeholder="请输入部门名称" style="width: 160px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="岗位">
                <el-input v-model="searchForm.jobName" placeholder="请输入岗位名称" style="width: 160px;" clearable></el-input>
            </el-form-item>
            <el-form-item label="人力规划">
                <el-input v-model="searchForm.name" placeholder="请输入人力规划名" style="width: 160px;" clearable></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
            <el-button type="primary" size="small" @click="_export" icon="el-icon-download" style="float:right;">导出</el-button>
        </el-form>
        <el-table :data="list" stripe>
            <el-table-column prop="name" label="人力规划" align="center"></el-table-column>
            <el-table-column prop="orgName" label="部门" align="center"></el-table-column>
            <el-table-column prop="jobName" label="岗位" align="center" min-width="120px"></el-table-column>
            <el-table-column prop="employeesNumber" label="编制人数" align="center"></el-table-column>
            <el-table-column prop="incumbencyNumber" label="在职人数" align="center"></el-table-column>
            <el-table-column prop="demandQuantity" label="需求人数" align="center"></el-table-column>
            <el-table-column
                v-for="(col, i) in columns"
                :prop="col.prop"
                :key="i"
                :label="col.label"
                align="center"
            >
            <template v-slot="{row}">
                {{_returnRoundNumber(row.interviewRoundsNumberList,col.id)}}
            </template>
            </el-table-column>
            <el-table-column prop="newResumeCount" label="应聘人数" align="center"></el-table-column>
            <el-table-column prop="waitEntryCount" label="录取人数" align="center"></el-table-column>
            <el-table-column prop="endEntryCount" label="入职人数" align="center"></el-table-column>
            <el-table-column prop="qualifiedRate" label="应聘合格率" align="center"></el-table-column>
            <el-table-column prop="completionRate" label="招聘完成率" align="center"></el-table-column>
        </el-table>
        <el-pagination
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.pageNo"
            :page-sizes="[20, 40, 80, 100]"
            :page-size="searchForm.pageSize"
            style="text-align:right;background-color: #fff;padding: 20px 0;"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.totalNum">
        </el-pagination>
    </div>
</template>
<script>
import { recruitAnalysis } from '@/api/overview';
export default {
    data() {
        return {
            loading: false,
            searchForm: {
                startMonth: '',
                endMonth: '',
                orgName: '',
                jobName: '',
                pageNo: 1,
                pageSize: 20,
                totalNum: 0
            },
            list: [],
            columns: []
        };
    },
    methods: {
        _getList() {
            this.loading = true;
            recruitAnalysis(this.searchForm).then((res) => {
                this.loading = false;
                const _list = [];

                res.interviewRoundsConfigList.forEach((item) => {
                    _list.push({label: item.interviewRoundsName, prop: '', id: item.id});
                });
                this.columns = _list;
                this.list = res.interviewRoundsList.list;
                this.searchForm.totalNum = res.interviewRoundsList.totalNum;
            });
        },
        _handleSizeChange(val) {
            this.searchForm.pageSize = val;
            this._getList();
        },
        _handleCurrentChange(val) {
            this.searchForm.pageNo = val;
            this._getList();
        },
        _returnRoundNumber(list, id) {
            for (const key in list) {
                if (list[key].interviewRoundsId == id) {
                    return list[key].number;
                }
            }
            return 0;
        },
        _export() {
            const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

            const url = `/v1/overview/recruit/analysis/export?params=${outStr}`;

            window.open(document.location.origin + url);
        }
    },
    mounted() {
        this._getList();
    }
};
</script>
<style lang="scss" scoped>

</style>
