<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-26 14:02:49
 * @LastEditTime: 2020-05-06 13:31:39
 -->
<template>
	<div v-loading="loading">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker v-model="searchForm.startMonth" type="month" value-format="yyyy-MM" format="yyyy-MM" style="width: 175px" />
				<span style="margin: 0 5px">-</span>
				<el-date-picker v-model="searchForm.endMonth" type="month" value-format="yyyy-MM" format="yyyy-MM" style="width: 175px" />
			</el-form-item>
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting" v-if="permissionControlBtns(pageName, 'Set')"
				>设置</el-button
			>
			<el-button type="primary" size="small" @click="sortDialog = true" icon="el-icon-sort" v-if="permissionControlBtns(pageName, 'Sort')"
				>排序</el-button
			>
			<el-button
				type="primary"
				size="small"
				@click="_export"
				style="float: right"
				icon="el-icon-download"
				v-if="permissionControlBtns(pageName, 'Export')"
				>导出</el-button
			>
		</el-form>
		<component
			class="tableBackground"
			:is="item.name"
			v-for="(item, index) in _returnTableList()"
			:key="index"
			:clientDetails="searchForm"
			v-show="_returnShow(item.name)"
		></component>
		<DrawerQuitCon
			:listNameArr="['forceListIds', 'orgClassConfigListIds', 'levelListIds', 'orgInfoListIds', 'orgJobConfigListIds']"
			:forceListNew="forceNew"
			:visible.sync="drawer"
			titleNew="离职纬度设置"
			@updateList="_updateList"
		></DrawerQuitCon>
		<el-dialog width="500px" :visible.sync="sortDialog" title="离职维度排序" center>
			<el-row :gutter="20" style="text-align: center">
				<Draggable :model="sortList2" @end="end">
					<el-col :span="6" v-for="(item, index) in sortList2" :key="index" style="margin-bottom: 10px">
						<el-button type="primary" size="small" style="padding: 9px 24px">{{ item }}</el-button>
					</el-col>
				</Draggable>
			</el-row>
			<div slot="footer" class="dialog-footer" style="clear: both; text-align: center">
				<el-button @click="sortDialog = false" size="small">取 消</el-button>
				<el-button type="primary" @click="_save" size="small">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import Draggable from 'vuedraggable';
import DrawerQuitCon from '../../components/drawerQuitCon';
import AgeTable from '../table/ageTable';
import EducationTable from '../table/educationTable';
import SexTable from '../table/sexTable';
import QuitReasonTable from '../table/quitReasonTable';
import ClassTable from '../table/classTable';
import OrgTable from '../table/orgTable';
import JobTable from '../table/jobTable';
import LevelTable from '../table/levelTable';
export default {
	props: { pageName: {} },
	components: { Draggable, DrawerQuitCon, AgeTable, EducationTable, SexTable, QuitReasonTable, ClassTable, OrgTable, JobTable, LevelTable },
	data() {
		return {
			loading: false,
			drawer: false,
			sortDialog: false,
			searchForm: {
				startMonth: '',
				endMonth: '',
				type: 0,
				forceListIds: [],
				orgClassConfigListIds: [],
				levelListIds: [],
				orgInfoListIds: [],
				orgJobConfigListIds: []
			},
			forceNew: [
				{ className: '离职原因', id: 1 },
				{ className: '年龄', id: 2 },
				{ className: '学历', id: 3 },
				{ className: '性别', id: 4 }
			],
			sortList: [],
			sortList2: ['年龄', '学历', '性别', '职类', '部门', '职级', '岗位', '原因'],
			sortList3: []
		};
	},
	methods: {
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_returnTableList() {
			if (this.sortList.length > 0) {
				return this.sortList;
			}
			return [
				{ name: 'AgeTable', title: '年龄', flag: this.searchForm.forceListIds.indexOf(2) >= 0 },
				{ name: 'EducationTable', title: '学历', flag: this.searchForm.forceListIds.indexOf(3) >= 0 },
				{ name: 'SexTable', title: '性别', flag: this.searchForm.forceListIds.indexOf(4) >= 0 },
				{ name: 'ClassTable', title: '职类', flag: this.searchForm.orgClassConfigListIds.length > 0 },
				{ name: 'OrgTable', title: '部门', flag: this.searchForm.orgInfoListIds.length > 0 },
				{ name: 'LevelTable', title: '职级', flag: this.searchForm.levelListIds.length > 0 },
				{ name: 'JobTable', title: '岗位', flag: this.searchForm.orgJobConfigListIds.length > 0 },
				{ name: 'QuitReasonTable', title: '原因', flag: this.searchForm.forceListIds.indexOf(1) >= 0 }
			];
		},
		_returnShow(name) {
			// eslint-disable-next-line default-case
			switch (name) {
				case 'AgeTable':
					return this.searchForm.forceListIds.indexOf(2) >= 0;
				case 'EducationTable':
					return this.searchForm.forceListIds.indexOf(3) >= 0;
				case 'SexTable':
					return this.searchForm.forceListIds.indexOf(4) >= 0;
				case 'ClassTable':
					return this.searchForm.orgClassConfigListIds.length > 0;
				case 'OrgTable':
					return this.searchForm.orgInfoListIds.length > 0;
				case 'LevelTable':
					return this.searchForm.levelListIds.length > 0;
				case 'JobTable':
					return this.searchForm.orgJobConfigListIds.length > 0;
				case 'QuitReasonTable':
					return this.searchForm.forceListIds.indexOf(1) >= 0;
			}
		},
		end(event) {
			const _list = event.target.innerText.split(/[\s\n]/);

			const _list2 = [];

			const _list3 = this._returnTableList();

			_list.forEach((item) => {
				_list3.forEach((child) => {
					if (child.title == item) {
						_list2.push(child);
					}
				});
			});
			this.sortList3 = _list2;
		},
		_save() {
			this.sortList = [...this.sortList3];
			this.sortDialog = false;
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/personnel/analysis/export?params=${outStr}`;

			window.open(document.location.origin + url);
		}
	},
	mounted() {
		const _now = new Date();
		const _year = _now.getUTCFullYear();
		const _month = _now.getMonth();

		this.searchForm.startMonth = String(`${_year}-${_month > 10 ? _month : `0${_month}`}`);
		this.searchForm.endMonth = String(`${_year}-${_month + 1 > 10 ? _month + 1 : `0${_month + 1}`}`);
	}
};
</script>
