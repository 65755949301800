<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-26 15:47:20
 * @LastEditTime: 2020-05-07 16:43:56
 -->
<template>
    <div style="margin-bottom: 20px;">
        <div class="home_title">学历结构分析</div>
        <el-table :data="list" stripe>
            <el-table-column
                v-for="(col, i) in columns"
                :prop="col.prop"
                :key="i"
                :label="col.label"
                align="center"
            ></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { educationAnalysis } from '@/api/overview';
export default {
    props: ['clientDetails'],
    data() {
        return {
            searchForm: {
                startMonth: '',
                endMonth: '',
                type: ''
            },
            columns: [
                {label: '名称', prop: 'name'},
                {label: '初中及以下', prop: 'juniorHighSchoolAndBelow'},
                {label: '高中/中专', prop: 'highSchool'},
                {label: '大专', prop: 'juniorCollege'},
                {label: '本科', prop: 'undergraduate'},
                {label: '研究生', prop: 'graduateStudent'},
                {label: '硕士', prop: 'master'},
                {label: '博士', prop: 'doctor'},
                {label: '其他', prop: 'other'}
            ],
            list: []
        };
    },
    methods: {
        _getList(obj) {
            educationAnalysis(obj).then((res) => {
                this.list = res;
            });
        }
    },
    watch: {
        clientDetails: {
            deep: true,
            // immediate : true,
            handler(newVal) {
                this.searchForm.startMonth = newVal.startMonth;
                this.searchForm.endMonth = newVal.endMonth;
                this.searchForm.type = newVal.type;
                this._getList(newVal);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.home_title {
    font-size: 16px;
    // padding-bottom: 30px;
    font-weight: bold;
    color: #2B3642;
    // border-bottom: 1px solid #EAEDF1;
    margin-bottom: 20px;
    &:before {
        display: inline-block;
        width: 8px;
        height: 20px;
        background: #498df0;
        content: " ";
        border-radius: 5px;
        vertical-align: text-bottom;
        margin-right: 8px;
    }
}
</style>
