<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 13:13:46
 * @LastEditTime: 2020-04-27 13:44:19
 -->
<template>
	<el-tabs v-model="activeName" class="app-container">
		<el-tab-pane label="人力资源异动分析" name="1" lazy v-if="permissionControl('/v1/overview/analysis/human/resource/changes')">
			<transactionPage></transactionPage>
		</el-tab-pane>
		<el-tab-pane label="离职人员分析" name="2" lazy v-if="permissionControl('/v1/overview/quit/reason/analysis')">
			<quitPage></quitPage>
		</el-tab-pane>
		<el-tab-pane label="在职人员分析" name="3" lazy v-if="permissionControl('/v1/overview/employment/age/analysis')">
			<incumbencyPage></incumbencyPage>
		</el-tab-pane>
		<el-tab-pane label="招聘分析" name="4" lazy v-if="permissionControl('/v1/overview/recruit/analysis')">
			<recruitPage></recruitPage>
		</el-tab-pane>
		<el-tab-pane label="人力资源成本分析" name="5" lazy v-if="permissionControl('/v1/overview/human/resource/cost/analysis')">
			<costPage></costPage>
		</el-tab-pane>
	</el-tabs>
</template>
<script>
import transactionPage from './page/transaction';
import quitPage from './page/quit';
import incumbencyPage from './page/incumbency';
import recruitPage from './page/recruit';
import costPage from './page/cost';
export default {
	components: { transactionPage, quitPage, incumbencyPage, recruitPage, costPage },
	data() {
		return {
			activeName: '1'
		};
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
