<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-26 16:32:57
 * @LastEditTime: 2020-05-07 16:44:16
 -->
<template>
    <div style="margin-bottom: 20px;">
        <div class="home_title">{{clientDetails.type == 0 ? '离职' : '在职'}}部门分析</div>
        <el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
            <el-form-item label="部门">
                <el-input v-model="searchForm.orgName" placeholder="输入部门名称" style="width: 160px;" clearable></el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click="_getList(searchForm)" icon="el-icon-search">查询</el-button>
            <el-button type="primary" size="small" v-if="this.searchForm.totalNum > 10" @click="_showMore" icon="el-icon-more-outline">查看更多</el-button>
        </el-form>
        <el-table :data="list" stripe>
            <el-table-column
                v-for="(col, i) in columns"
                :prop="col.prop"
                :key="i"
                :label="col.label"
                align="center"
            ></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { departmentAnalysis } from '@/api/overview';
export default {
    props: ['clientDetails'],
    data() {
        return {
            searchForm: {
                startMonth: '',
                endMonth: '',
                type: '',
                orgIds: '',
                orgName: '',
                pageNo: 1,
                pageSize: 10,
                totalNum: 0
            },
            columns: [
                {label: '部门', prop: 'name'},
                {label: `${this.clientDetails.type == 1 ? '在职' : '离职'}人数`, prop: 'number'},
                {label: `占${this.clientDetails.type == 1 ? '在职' : '离职'}人数比`, prop: 'proportion'}
            ],
            list: []
        };
    },
    methods: {
        _getList(obj) {
            departmentAnalysis(obj).then((res) => {
                this.list = res.list;
                this.searchForm.totalNum = res.totalNum;
            });
        },
        _showMore() {
            const _obj = {...this.searchForm};

            _obj.pageSize = 10000;
            this._getList(_obj);
        }
    },
    watch: {
        clientDetails: {
            deep: true,
            // immediate : true,
            handler(newVal) {
                this.searchForm.startMonth = newVal.startMonth;
                this.searchForm.endMonth = newVal.endMonth;
                this.searchForm.type = newVal.type;
                this.searchForm.orgIds = newVal.orgInfoListIds;
                this._getList(this.searchForm);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.home_title {
    font-size: 16px;
    // padding-bottom: 30px;
    font-weight: bold;
    color: #2B3642;
    // border-bottom: 1px solid #EAEDF1;
    margin-bottom: 20px;
    &:before {
        display: inline-block;
        width: 8px;
        height: 20px;
        background: #498df0;
        content: " ";
        border-radius: 5px;
        vertical-align: text-bottom;
        margin-right: 8px;
    }
}
</style>
