<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 14:43:05
 * @LastEditTime: 2020-05-07 16:58:28
 -->
<template>
	<div v-loading="loading">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker v-model="searchForm.startMonth" type="month" value-format="yyyy-MM" format="yyyy-MM" style="width: 175px" />
				<span style="margin: 0 5px">-</span>
				<el-date-picker v-model="searchForm.endMonth" type="month" value-format="yyyy-MM" format="yyyy-MM" style="width: 175px" />
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
			<el-button type="primary" size="small" @click="_export" style="float: right" icon="el-icon-download">导出</el-button>
		</el-form>
		<el-table :data="list" stripe :row-class-name="_rowClassName" class="tableBackground">
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" align="center"></el-table-column>
		</el-table>
		<DrawerCon :visible.sync="drawer" :listNameArr="['wagesConfigIds']" :mixAble="1" @updateList="_updateList"></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../../components/drawerCon';
import { humancostAnalysis } from '@/api/overview';
export default {
	components: { DrawerCon },
	data() {
		return {
			loading: false,
			drawer: false,
			searchForm: {
				startMonth: '',
				endMonth: '',
				wagesConfigIds: []
			},
			dateRange: '',
			list: [],
			columns: [
				{ prop: 'name', label: '类型' },
				{ prop: 'totalMoney', label: '实发总额' },
				{ prop: 'number', label: '人数' },
				{ prop: 'perCapitaCost', label: '人均工资/成本' },
				{ prop: 'differenceAmount', label: '与上月相比(增减金额)' }
			]
		};
	},
	methods: {
		_getList() {
			for (const key in this.searchForm) {
				if (this.searchForm[key] === '' || this.searchForm[key].length == 0) {
					return false;
				}
			}
			this.loading = true;
			humancostAnalysis(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res;
			});
		},
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/human/resource/cost/analysis/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		},
		_rowClassName({ rowIndex }) {
			if (rowIndex == 0) {
				return 'firstColor';
			}
		}
	},
	mounted() {
		const _now = new Date();
		const _year = _now.getUTCFullYear();
		const _month = _now.getMonth();

		this.searchForm.startMonth = String(`${_year}-${_month - 1 > 10 ? _month - 1 : `0${_month - 1}`}`);
		this.searchForm.endMonth = String(`${_year}-${_month > 10 ? _month : `0${_month}`}`);
		this.dateRange = [this.searchForm.startMonth, this.searchForm.endMonth];
		// this._getList();
	},
	watch: {
		// 'searchForm.startMonth' : {
		//     deep : true,
		//     handler(){
		//         this._getList();
		//     }
		// },
		// 'searchForm.endMonth' : {
		//     deep : true,
		//     handler(){
		//         this._getList();
		//     }
		// },
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-table__row.firstColor {
	color: #ff7371;
}
</style>
