<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-24 11:18:21
 * @LastEditTime: 2020-04-29 20:15:36
 -->
<template>
	<el-drawer class="moryeDrawer" v-bind="$attrs" v-on="$listeners" size="40%" :with-header="false">
		<div class="moryeDrawer_container">
			<div class="drawersettingHeader">
				<el-row :gutter="20">
					<el-col :span="8">
						<el-link icon="el-icon-arrow-left" @click="$emit('update:visible', false)">返回</el-link>
					</el-col>
					<el-col :span="8" style="text-align: center">{{ titleNew }}</el-col>
					<el-col :span="8" style="text-align: right">
						<el-link icon="el-icon-refresh-right" style="margin-right: 10px" @click="_reSet">重置</el-link>
						<el-link icon="el-icon-copy-document" @click="_setAll">全选</el-link>
					</el-col>
				</el-row>
			</div>
			<div class="moryeDrawer_container_sc">
				<el-collapse v-model="activeNames">
					<el-collapse-item name="1">
						<template slot="title">
							<div class="home_title" style="border: 0; padding: 0; margin: 0; padding-left: 20px">离职维度</div>
						</template>
						<div class="drawersetting">
							<div class="drawersetting_box">
								<div>
									<div class="drawersetting_title">
										基本信息
										<el-link
											class="drawersetting_title_btn"
											icon="el-icon-copy-document"
											@click="_clickAll(listNameArr[0], forceList)"
											:type="_returnIndex2(listNameArr[0], forceList) ? 'primary' : ''"
											:underline="false"
											>全选</el-link
										>
									</div>
									<section>
										<div style="display: inline-block; margin: 0 10px 10px 0" v-for="(child, i) in forceList" :key="i">
											<el-button
												:type="_returnIndex(listNameArr[0], child.id) ? 'primary' : ''"
												:style="
													!_returnIndex(listNameArr[0], child.id)
														? 'background-color: #F5F5F5;color: #606266;border:none'
														: ''
												"
												@click="_btnClick(child.id, listNameArr[0])"
												size="small"
												>{{ child.className }}</el-button
											>
										</div>
									</section>
								</div>
								<div>
									<div class="drawersetting_title">
										职类
										<el-link
											class="drawersetting_title_btn"
											icon="el-icon-copy-document"
											@click="_clickAll(listNameArr[1], orgClassConfigList)"
											:type="_returnIndex2(listNameArr[1], orgClassConfigList) ? 'primary' : ''"
											:underline="false"
											>全选</el-link
										>
									</div>
									<section style="width: 80%">
										<div v-for="(child, i) in orgClassConfigList" :key="i" style="display: inline-block; margin: 0 10px 10px 0">
											<el-button
												:type="_returnIndex(listNameArr[1], child.id) ? 'primary' : ''"
												:style="
													!_returnIndex(listNameArr[1], child.id)
														? 'background-color: #F5F5F5;color: #606266;border:none'
														: ''
												"
												@click="_btnClick(child.id, listNameArr[1])"
												size="small"
												>{{ child.className }}</el-button
											>
										</div>
									</section>
								</div>
								<div>
									<div class="drawersetting_title">
										职级
										<el-link
											class="drawersetting_title_btn"
											icon="el-icon-copy-document"
											@click="_clickAll(listNameArr[2], levelList)"
											:type="_returnIndex2(listNameArr[2], levelList) ? 'primary' : ''"
											:underline="false"
											>全选</el-link
										>
									</div>
									<section>
										<div v-for="(child, i) in levelList" :key="i" style="display: inline-block; margin: 0 10px 10px 0">
											<el-button
												:type="_returnIndex(listNameArr[2], child) ? 'primary' : ''"
												:style="
													!_returnIndex(listNameArr[2], child) ? 'background-color: #F5F5F5;color: #606266;border:none' : ''
												"
												@click="_btnClick(child, listNameArr[2])"
												size="small"
												>{{ child }}级</el-button
											>
										</div>
									</section>
								</div>
								<div>
									<div class="drawersetting_title">
										部门
										<el-link
											class="drawersetting_title_btn"
											icon="el-icon-copy-document"
											@click="_clickAll(listNameArr[3], orgInfoList)"
											:type="_returnIndex2(listNameArr[3], orgInfoList) ? 'primary' : ''"
											:underline="false"
											>全选</el-link
										>
									</div>
									<section>
										<div v-for="(child, i) in orgInfoList" :key="i" style="display: inline-block; margin: 0 10px 10px 0">
											<el-button
												:type="_returnIndex(listNameArr[3], child.id) ? 'primary' : ''"
												:style="
													!_returnIndex(listNameArr[3], child.id)
														? 'background-color: #F5F5F5;color: #606266;border:none'
														: ''
												"
												@click="_btnClick(child.id, listNameArr[3])"
												size="small"
												>{{ child.orgName }}</el-button
											>
										</div>
									</section>
								</div>
								<div>
									<div class="drawersetting_title">
										岗位
										<el-link
											class="drawersetting_title_btn"
											icon="el-icon-copy-document"
											@click="_clickAll(listNameArr[4], orgJobConfigList)"
											:type="_returnIndex2(listNameArr[4], orgJobConfigList) ? 'primary' : ''"
											:underline="false"
											>全选</el-link
										>
									</div>
									<section>
										<div v-for="(child, i) in orgJobConfigList" :key="i" style="display: inline-block; margin: 0 10px 10px 0">
											<el-button
												:type="_returnIndex(listNameArr[4], child.id) ? 'primary' : ''"
												:style="
													!_returnIndex(listNameArr[4], child.id)
														? 'background-color: #F5F5F5;color: #606266;border:none'
														: ''
												"
												@click="_btnClick(child.id, listNameArr[4])"
												size="small"
												>{{ child.jobName }}</el-button
											>
										</div>
									</section>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
	</el-drawer>
</template>
<script>
import { dimensionList } from '@/api/overview';
export default {
	props: ['listNameArr', 'forceListNew', 'titleNew'],
	data() {
		return {
			activeNames: '1',
			forceList: this.forceListNew,
			forceListIds: [],
			orgClassConfigListIds: [],
			orgJobConfigListIds: [],
			orgInfoListIds: [],
			levelListIds: [],
			orgClassConfigList: [],
			orgJobConfigList: [],
			orgInfoList: [],
			levelList: [],
			detail: ''
		};
	},
	methods: {
		_getDimensionList() {
			dimensionList({}).then((res) => {
				this.detail = res;
				this.orgClassConfigList = res.orgClassConfigList;
				this.orgJobConfigList = res.orgJobConfigList;
				this.orgInfoList = res.orgInfoList;
				this.levelList = res.levelList;
				this._setAll();
			});
		},
		_btnClick(id, list) {
			const _index = this[list].indexOf(id);

			if (_index < 0) {
				this[list].push(id);
			} else {
				this[list].splice(_index, 1);
			}
			this.$emit('updateList', { listName: list, listDetail: this[list] });
		},
		_returnIndex(list, id) {
			const _index = this[list].indexOf(id);

			if (_index < 0) {
				return false;
			}
			return true;
		},
		_returnIndex2(list, list2) {
			for (const key in list2) {
				const _index = this[list].indexOf(list2[key].id || list2[key]);

				if (_index >= 0) {
					return true;
				}
			}
			return false;
		},
		_clickAll(list, list2) {
			const _flag = this._returnIndex2(list, list2);

			if (_flag) {
				for (const key in list2) {
					this[list].forEach((item, index) => {
						if (item == list2[key].id || item == list2[key]) {
							this[list].splice(index, 1);
						}
					});
				}
			} else {
				const _list = [];

				list2.forEach((item) => _list.push(item.id || item));
				this[list] = _list;
			}
			this.$emit('updateList', { listName: list, listDetail: this[list] });
		},
		_reSet() {
			this.listNameArr.forEach((item) => {
				this[item] = [];
				this.$emit('updateList', { listName: item, listDetail: [] });
			});
		},
		_setAll() {
			this._reSet();
			this.forceList.forEach((child) => {
				this.forceListIds.push(child.id);
			});
			this.orgClassConfigList.forEach((child) => {
				this.orgClassConfigListIds.push(child.id);
			});
			this.levelList.forEach((child) => {
				this.levelListIds.push(child);
			});
			this.orgInfoList.forEach((child) => {
				this.orgInfoListIds.push(child.id);
			});
			this.orgJobConfigList.forEach((child) => {
				this.orgJobConfigListIds.push(child.id);
			});
			this.listNameArr.forEach((item) => {
				this.$emit('updateList', { listName: item, listDetail: this[item] });
			});
		}
	},
	mounted() {
		this._getDimensionList();
	}
};
</script>
<style lang="scss" scoped>
.moryeDrawer {
	::v-deep.el-drawer__header {
		display: none;
	}
	::v-deep.el-drawer {
		overflow-y: scroll;
	}
	.moryeDrawer_container {
		height: 100%;
		display: flex;
		flex-direction: column;
		.drawersettingHeader {
			padding: 20px;
			border-bottom: 1px solid #c5cdda;
		}
		.moryeDrawer_container_sc {
			flex: 1;
			.drawersetting {
				padding: 20px;
				.drawersetting_box {
					padding-left: 20px;
					.drawersetting_title {
						font-size: 16px;
						color: #2b3642;
						margin-bottom: 20px;
						.drawersetting_title_btn {
							margin-left: 10px;
						}
					}
				}
			}
			.home_title {
				font-size: 16px;
				font-weight: bold;
				color: #2b3642;
				&:before {
					display: inline-block;
					width: 8px;
					height: 20px;
					background: #498df0;
					content: ' ';
					border-radius: 5px;
					vertical-align: text-bottom;
					margin-right: 8px;
				}
			}
		}
	}
}
::v-deep.el-button {
	white-space: break-spaces;
}
</style>
