<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-26 15:56:36
 * @LastEditTime: 2020-04-27 14:20:41
 -->
<template>
    <div style="margin-bottom: 20px;">
        <div class="home_title">离职原因分析</div>
        <el-table :data="list" stripe>
            <el-table-column
                v-for="(col, i) in columns"
                :prop="col.prop"
                :key="i"
                :label="col.label"
                align="center"
            ></el-table-column>
        </el-table>
    </div>
</template>
<script>
import { quitReasonAnalysis } from '@/api/overview';
export default {
    props: ['clientDetails'],
    data() {
        return {
            searchForm: {
                startMonth: '',
                endMonth: '',
                type: ''
            },
            columns: [
                {label: '离职原因', prop: 'reason'},
                {label: '离职人数', prop: 'number'},
                {label: '所占比率', prop: 'proportion'},
                {label: '主要改善措施', prop: 'improvementMeasures'}
            ],
            list: []
        };
    },
    methods: {
        _getList(obj) {
            quitReasonAnalysis(obj).then((res) => {
                this.list = res;
            });
        }
    },
    watch: {
        clientDetails: {
            deep: true,
            // immediate : true,
            handler(newVal) {
                this.searchForm.startMonth = newVal.startMonth;
                this.searchForm.endMonth = newVal.endMonth;
                this.searchForm.type = newVal.type;
                this._getList(newVal);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.home_title {
    font-size: 16px;
    // padding-bottom: 30px;
    font-weight: bold;
    color: #2B3642;
    // border-bottom: 1px solid #EAEDF1;
    margin-bottom: 20px;
    &:before {
        display: inline-block;
        width: 8px;
        height: 20px;
        background: #498df0;
        content: " ";
        border-radius: 5px;
        vertical-align: text-bottom;
        margin-right: 8px;
    }
}
</style>
