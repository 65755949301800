import { render, staticRenderFns } from "./employmentAgeTable.vue?vue&type=template&id=7ddc1345&scoped=true&"
import script from "./employmentAgeTable.vue?vue&type=script&lang=js&"
export * from "./employmentAgeTable.vue?vue&type=script&lang=js&"
import style0 from "./employmentAgeTable.vue?vue&type=style&index=0&id=7ddc1345&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ddc1345",
  null
  
)

export default component.exports