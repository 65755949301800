<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 13:44:54
 * @LastEditTime: 2020-05-06 15:13:39
 -->
<template>
	<div v-loading="loading">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					:clearable="false"
					v-model="searchForm.yearMonth"
					type="month"
					value-format="yyyy-MM"
					format="yyyy-MM"
					style="width: 160px"
					placeholder="选择月份"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item label="部门">
				<el-input v-model="search" placeholder="请输入部门名称" style="width: 160px" clearable></el-input>
			</el-form-item>
			<div style="float: right; margin-left: 10px" v-if="permissionControlBtns(pageName, 'Filter')">
				<el-popover placement="bottom-end" trigger="click">
					<el-radio-group v-model="searchForm.containsLowerOrg">
						<el-radio :label="0">本层级</el-radio>
						<el-radio :label="1">含下级</el-radio>
					</el-radio-group>
					<el-button
						class="vxe-button type--button is--circle"
						slot="reference"
						title="筛选显示列"
						style="padding: 8px 8px 7px 8px; border-radius: 50%"
					>
						<i class="vxe-button--icon vxe-icon-custom-column"></i>
					</el-button>
				</el-popover>
			</div>
			<el-button
				type="primary"
				size="small"
				@click="_export"
				style="float: right"
				icon="el-icon-download"
				v-if="permissionControlBtns(pageName, 'Export')"
				>导出</el-button
			>
			<!-- <el-button type="primary" size="small" @click="_getList">查询</el-button> -->
		</el-form>
		<el-table
			:data="list"
			row-key="id"
			class="tableBackground"
			ref="treeTable"
			stripe
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column prop="orgName" label="部门" align="center"></el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" :label="col.label" align="center">
				<div slot-scope="{ row }" v-if="col.prop == 'quitRate'">{{ row.quitRate }}%</div>
				<div slot-scope="{ row }" v-else>
					{{ row[col.prop] }}
				</div>
			</el-table-column>
		</el-table>
	</div>
</template>
<script>
import { humanAnalysis } from '@/api/overview';
export default {
	props: { pageName: {} },
	data() {
		return {
			loading: false,
			searchForm: {
				yearMonth: '',
				containsLowerOrg: 0
			},
			search: '',
			dateRange: '',
			list: [],
			list2: [],
			expendRows: []
			// columns: [
			// 	// {prop: 'orgName',label : '部门'},
			// 	{ prop: 'employeesNumber', label: '编织人数' },
			// 	{ prop: 'monthStartNumber', label: '月初人数' },
			// 	{ prop: 'recruitsNumber', label: '入职人数' },
			// 	{ prop: 'quitNumber', label: '离职人数' },
			// 	{ prop: 'quitRate', label: '离职率' },
			// 	{ prop: 'moveInNumber', label: '调入人数' },
			// 	{ prop: 'moveOutNumber', label: '调出人数' },
			// 	{ prop: 'monthEndNumber', label: '月末人数' }
			// ]
		};
	},
	computed: {
		columns() {
			const suffix = this.searchForm.containsLowerOrg === 1 ? '（含下级）' : '（本层级）';

			return [
				// {prop: 'orgName',label : '部门'},
				{ prop: 'employeesNumber', label: `计划人数${suffix}` },
				{ prop: 'monthStartNumber', label: `月初人数${suffix}` },
				{ prop: 'recruitsNumber', label: `入职人数${suffix}` },
				{ prop: 'quitNumber', label: `离职人数${suffix}` },
				{ prop: 'quitRate', label: `离职率${suffix}` },
				{ prop: 'moveInNumber', label: `调入人数${suffix}` },
				{ prop: 'moveOutNumber', label: `调出人数${suffix}` },
				{ prop: 'monthEndNumber', label: `月末人数${suffix}` }
			];
		}
	},
	methods: {
		_getList() {
			this.loading = true;
			humanAnalysis(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res;
				this.list2 = res;
			});
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/analysis/human/resource/changes/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		searchData(data, val) {
			const newData = [];

			data.forEach((v) => {
				if (v.children && v.children.length > 0) {
					let flag = false;

					this.expendRows.push(v);
					if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
						flag = true;
						newData.push(v);
					}
					const children = this.searchData(v.children, val);
					const obj = {
						...v,
						children
					};

					if (children && children.length && !flag) {
						newData.push(obj);
					}
				} else if (v.orgName && v.orgName.toLowerCase().match(new RegExp(val.toLowerCase()))) {
					this.expendRows.push(v);
					newData.push(v);
				}
			});
			return newData;
		}
	},
	mounted() {
		const _now = new Date();

		const _year = _now.getFullYear();

		let _month = _now.getMonth();

		_month = _month + 1 >= 10 ? _month + 1 : `0${_month + 1}`;
		this.searchForm.yearMonth = String(`${_year}-${_month}`);
		// this._getList();
	},
	watch: {
		search: {
			handler(val) {
				if (val) {
					this.expendRows = [];
					this.list = this.searchData(this.list, val);
					this.expendRows.forEach((v) => {
						this.$refs.treeTable.toggleRowExpansion(v, true);
					});
				} else {
					this.list = this.list2;
				}
			},
			immediate: true
		},
		'searchForm.yearMonth': {
			// deep: true,
			handler(newVal) {
				this.searchForm.yearMonth = newVal;
				this._getList();
			}
		},
		'searchForm.containsLowerOrg': {
			// deep: true,
			handler(newVal) {
				this.searchForm.containsLowerOrg = newVal;
				this._getList();
			}
		}
	}
};
</script>
<style lang="scss" scoped></style>
